<template>
  <div class="home">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="dialogConfirm"
        >新增</el-button
      > -->
      <el-select
        v-model="value"
        placeholder="请选择查询订单状态"
        @change="getDataList()"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="daterange"
        type="daterange"
        style="width: 250px; margin-left: 10px"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="changeTime"
      >
      </el-date-picker>

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入店铺名称"
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      :max-height="height"
      v-loading="loading"
    >
      <!-- <el-table-column prop="CompanyName" label="所属公司" width="250">
        </el-table-column> -->
      <el-table-column prop="Id" label="编号" width="190px">
        <template slot-scope="scope">
          B{{
            scope.row.Id.replace(/\s/g, "").replace(/(.{4})/g, "$1 ")
          }}</template
        ></el-table-column
      >
      <el-table-column prop="CreateTime" label="日期" width="120">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="BuyerStoreName" label="买家"> </el-table-column>
      <el-table-column prop="RealName" label="联系人"> </el-table-column>
      <el-table-column prop="Period" label="账期"
        ><template slot-scope="scope">
          {{ scope.row.Period }}
          <!-- ({{ scope.row.AccountingStartTime && scope.row.AccountingEndTime ? "/" : "" }}) -->
          <!-- {{ scope.row.AccountingStartTime }} -->
          <!-- {{ scope.row.AccountingEndTime }} -->

          天</template
        >
      </el-table-column>
      <!--      :formatter="stateFormat"-->
      <el-table-column prop="CreditAmount" label="总金额">
        <template slot-scope="scope">
          {{ toThousands(scope.row.CreditAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="IsConfirm" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag class="tableEltag" v-if="scope.row.IsConfirm == 0"
            >待确认</el-tag
          >
          <el-tag
            class="tableEltag"
            v-if="scope.row.IsConfirm == 1"
            type="success"
            >已确认</el-tag
          >
          <el-tag
            class="tableEltag"
            v-if="scope.row.IsConfirm == 2"
            type="danger"
            >已打回</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <!-- <el-popconfirm
            v-if="scope.row.IsConfirm == 0"
            confirm-button-text="确认"
            cancel-button-text="取消"
            cancel-button-type=""
            title="您确认执行此操作吗?"
            @confirm="differentConfrim(scope.row)"
            >-->
          <el-link
            slot="reference"
            type="primary"
            class="linkmargin"
            :underline="false"
            @click="handdate(scope.row)"
            >明细</el-link
          >
          <el-link
            v-if="scope.row.IsConfirm == 0"
            slot="reference"
            type="primary"
            class="linkmargin"
            :underline="false"
            @click="handleInvalidate(scope.row, 1)"
            >确认</el-link
          >
          <!-- </el-popconfirm> -->

          <el-link
            v-if="scope.row.IsConfirm == 0"
            type="danger"
            class="linkmargin"
            :underline="false"
            @click="handleInvalidate(scope.row, 2)"
            >拒单
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total,prev, pager, next,sizes"
      :total="pagination.total"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizeOptions"
      style="text-align: right; margin-top: 8px; padding: 0"
      @current-change="currentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>

    <el-dialog
      title="数据抓取中..."
      width="240px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="batchVisible"
      custom-class="batchClass"
    >
      <div style="text-align: center">
        <el-progress type="circle" :percentage="percentage"></el-progress>
      </div>
    </el-dialog>
    <div>
      <!--      {{ stateFormat(0, 0, this.qian) }} -->
      <h2>赊账总计：{{ toThousands(this.qian) }} 元</h2>
    </div>
    <OrderForm ref="OrderForm" :parentObj="this" @sub="getDataList" />
  </div>
</template>

<script>
import { getMonthRange, toThousands, formatDate } from "../../utils/Tools";
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
import radioGroup from "@/components/CompanyRadioGroup.vue";
import OrderForm from "./OrderForm.vue";
import { log } from "console";
export default {
  name: "Home",
  components: { radioGroup, OrderForm },
  data() {
    return {
      // 金额格式千分位
      toThousands,
      // 日期
      daterange: [],
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "待确认",
        },
        {
          value: "1",
          label: "已确认",
        },
        {
          value: "2",
          label: "已打回",
        },
      ],
      value: "",
      activeName: "0",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      tableData: [],
      Price: "",
      queryParam: { SupplieId: "全部", Status: "0", Condition: "" },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      tabList: [
        { label: "全部", name: "first" },
        { label: "待签收", name: "0" },
        { label: "已签收", name: "1" },
        { label: "差异", name: "3" },
        { label: "拒收", name: "2" },
        { label: "作废", name: "4" },
      ],
      ip: "",
      inputDialogVisible: false,
      inputVisible: "",
      inputValue: "",
      dynamicTags: [],
      selectTags: [],
      rowId: "",
      qian: 0,
      batchVisible: false,
      percentage: 0,
      rowData: {},
    };
  },

  mounted() {
    // this.getTemplateList()
    this.ip = sessionStorage.getItem("ip");
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 金额千分位
    stateFormat(row, column, cellValue) {
      if (cellValue) {
        return Number(cellValue)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
    // async dialogBatch(pageIndex) {
    //   if (pageIndex == 1) {
    //     if (
    //       !this.queryParam.SupplieId ||
    //       this.queryParam.SupplieId.split(",").length > 1 ||
    //       this.queryParam.SupplieId === "全部"
    //     ) {
    //       return this.$message.warning("请选择公司后新增");
    //     }
    //     this.batchVisible = true;
    //     this.percentage = 0;
    //   }
    //   await this.$http
    //     .post(`/BO/ApplicationForm/GetSellerOrderList`, {
    //       PageIndex: this.pagination.current,
    //       PageRows: this.pagination.pageSize,
    //       SortField: "CreateTime",
    //       SortType: "desc",
    //       Search: {
    //         CompanyId: this.queryParam.SupplieId,
    //         IsConfirm: this.value,
    //       },
    //     })
    //     .then(async (res) => {
    //       if (res.Success) {
    //         let result = JSON.parse(res.Data);
    //         let newArr = result.data.list.map((item) => {
    //           return {
    //             Billid: item.order_no,
    //             Customer: item.user_name,
    //             CustomerId: item.user_code,
    //             Address: item.address_detail,
    //             CustomerPhone: item.receive_tel,
    //             create_time: item.delivery_date,
    //             Content: JSON.stringify({
    //               Subs: item.commodity_list.map((im) => {
    //                 return {
    //                   Name: im.commodity_name,
    //                   cInvCode: im.commodity_code,
    //                   Quantity: im.convert_amount,
    //                   GroupName: im.unit_sell,
    //                   iInvRCost: im.price,
    //                   cInvStd: im.summary,
    //                   SumiInvRCost: (im.convert_amount * im.price).toFixed(2),
    //                 };
    //               }),
    //             }),
    //           };
    //         });
    //         await this.saveDialogBatch(newArr);
    //         this.percentage = Math.floor(
    //           (pageIndex / result.data.pageParams.total_page) * 100
    //         );
    //         if (pageIndex >= result.data.pageParams.total_page) {
    //           this.batchVisible = false;
    //           return;
    //         }
    //         await this.dialogBatch(pageIndex + 1);
    //       }
    //     });
    // },
    // 时间选择
    changeTime(e) {
      if (e) {
        this.queryParam.StartTime = e[0];
        this.queryParam.EndTime = e[1];
      } else {
        this.queryParam.StartTime = "";
        this.queryParam.EndTime = "";
      }

      // 获取列表数据
      this.getDataList();
    },
    getPrice() {
      this.$http
        .post("/BO/ApplicationForm/GetSellerOrderSum", {
          SortField: "CreateTime",
          SortType: "desc",
          Search: {
            keywords: this.queryParam.keyword,
            StartDate: "",
            EndDate: "",
            CompanyId: this.queryParam.SupplieId,
            IsConfirm: this.value,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.Price = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    async saveDialogBatch(arr) {
      await this.$http
        .post("/BO/BillList/SaveBillLists", {
          CompanyId: this.queryParam.SupplieId,
          billLists: arr,
        })
        .then((res) => {
          this.getDataList();
        });
    },
    handdate(row) {
      this.$refs.OrderForm.openForm(row);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (
        inputValue &&
        this.dynamicTags.findIndex((item) => item == this.inputValue) == -1
      ) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // // 同意差异
    // differentConfrim(row) {
    //   this.$http
    //     .post("/BO/BillList/ConfigSubmit?Id=" + row.Id + "&IP=" + this.ip)
    //     .then((res) => {
    //       if (res.Success) {
    //         this.getDataList();
    //         this.$message.success("操作成功");
    //       } else {
    //         this.$message.error(res.Msg);
    //       }
    //     });
    // },
    // 重启
    resetStart(row, type) {
      let url = "";
      if (type == "confirm") url = "/BO/BillList/WebForm";
      else url = "/BO/BillList/Processing";
      this.$http.post(url + "?Id=" + row.Id).then((res) => {
        if (res.Success) {
          this.getDataList();
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },

    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },

    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    // 页签切换
    handleClick(tab, event) {
      this.pagination = {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      };
      this.queryParam.Status = this.activeName + "";
      if (this.activeName == "first") this.queryParam.Status = "";
      this.getDataList();
    },

    // 获取当前行的详情数据
    getRowData(Id) {
      this.$http
        .post("/BO/ApplicationForm/GetApplicationDetail?id=" + Id)
        .then((res) => {
          if (res.Success) {
            console.log("获取详情当前行", res.Data);
            this.rowData = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    //调用回执单
    getAppDataByLocation(Billid) {
      let detailsList = this.rowData.details.map((item) => {
        return {
          Name: item.InventName,
          cInvStd: item.cInvStd ? item.cInvStd : "",
          Weight: item.Weight ? item.Weight : 0,
          Weight1: item.Weight1 ? item.Weight1 : 0,
          Quantity: item.Quantity,
          GroupName: item.GroupName ? item.GroupName : "",
          iInvRCost: item.Price,
          SumiInvRCost: item.Price * item.Quantity,
          cInvCode: item.cInvCode ? item.cInvCode : "",
          Batch: item.Batch ? item.Batch : "",
          InventId: item.InventId ? item.InventId : "",
        };
      });

      console.log("打印商品列表", detailsList, this.rowData.details);
      let obj = {
        Billid: Billid,
        CustomerId: this.rowData.CompanyId + "-" + this.rowData.BuyerStoreId,
        Company: this.rowData.CompanyId,
        DomainName: "hzq",
        Content: JSON.stringify({
          BillId: Billid,
          Customer: this.rowData.BuyerStoreName,
          Subs: JSON.stringify(detailsList),
        }),
      };
      console.log(
        "传递过来生成回执单的参数",
        Billid,
        this.rowData,
        obj,
        detailsList
      );

      this.$http.post("/BO/BillList/AppDataByLocation", obj).then((res) => {
        if (res.Success) {
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
      // console.log("传递过来生成回执单的参数", Billid, this.rowData);
    },
    // 确认和作废
    handleInvalidate(scope, type) {
      console.log("点击确认el", scope, type);
      var that = this;
      this.getRowData(scope.Id);
      this.$confirm("您确认要执行此操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post("/BO/ApplicationForm/ConfirmApplicationForm", {
            Id: scope.Id,
            IsConfirm: type,
          })
          .then((res) => {
            if (res.Success) {
              if (type == 1) {
                // 只有确认条件调用回执单
                this.getAppDataByLocation(res.Data);
                return;
              } else {
                this.$message.success("操作成功");
                this.getDataList();
              }
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    // 模板列表
    getTemplateList() {
      this.$http.post("/Base/Base_Template/GetDataList", {}).then((res) => {
        if (res.Success) {
          this.templateList = res.Data;
        }
      });
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/ApplicationForm/GetSellerOrderList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: "CreateTime",
          SortType: "desc",
          Search: {
            CompanyId: this.queryParam.SupplieId,
            IsConfirm: this.value,
            keywords: this.queryParam.keyword,
            EndDate: this.queryParam.EndTime,
            StartDate: this.queryParam.StartTime,
          },
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            this.qian = resJson.MoneyTotal;

            this.getPrice();
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
// /deep/.batchClass{
//   // background-color: #ffffffe9;
// }
.searchNav .el-button {
  background: #3362ec;
  border-color: #3362ec;
  color: #fff;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #3362ec;
  border-color: #3362ec;
  box-shadow: -1px 0 0 0 #3362ec;
}
/deep/.el-radio-button__inner:hover {
  color: #3362ec;
}
.el-ra .el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.button-new-tag {
  margin-bottom: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-bottom: 10px;
  // vertical-align: bottom;
}

.tableEltag {
  margin: 0;
}

/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
</style>
