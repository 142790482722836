var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-drawer',{staticStyle:{"overflow-x":"hidden"},attrs:{"title":_vm.title,"visible":_vm.drawer,"direction":_vm.direction,"before-close":_vm.handleClose,"destroy-on-close":"","custom-class":"drawerClass","size":"50%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticStyle:{"overflow":"hidden","width":"100%","margin-left":"20px"},attrs:{"model":_vm.entity}},[_c('span',{staticStyle:{"float":"left","width":"50%"}},[_vm._v(" 店铺: "+_vm._s(_vm.entity.BuyerStoreName)+" ")]),_c('span',{staticStyle:{"float":"left","width":"50%","margin-left":"-50px"}},[_vm._v(" 单号: B"+_vm._s(_vm.entity.Id ? _vm.entity.Id.replace(/\s/g, "").replace(/(.{4})/g, "$1 ") : "")+" ")])]),_c('div',{staticStyle:{"overflow":"hidden","width":"100%","margin-left":"20px","margin-top":"20px","margin-bottom":"30px"},attrs:{"model":_vm.entity}},[_c('span',{staticStyle:{"float":"left","width":"50%"}},[_vm._v(" 日期: "+_vm._s(_vm.entity.CreateTime ? _vm.entity.CreateTime.substring(0, 10) : "")+" ")]),_c('span',{staticStyle:{"float":"left","width":"50%","margin-left":"-50px"}},[_vm._v(" 客户: "+_vm._s(_vm.entity.CompanyName)+" ")])]),_c('el-table',{staticStyle:{"width":"100%","left":"10px"},attrs:{"data":_vm.details}},[_c('el-table-column',{attrs:{"prop":"InventName","label":"品名/编码","width":"330"}}),_c('el-table-column',{attrs:{"prop":"Quantity","label":"数量"}}),_c('el-table-column',{attrs:{"prop":"Price","label":"单价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.toThousands(scope.row.Price))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"Money","label":"金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.toThousands(scope.row.Money))+" ")]}}])})],1),(_vm.obj.IsConfirm == 0)?_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    })},[_c('el-button',{on:{"click":function($event){return _vm.handleInvalidate(_vm.obj, 2)}}},[_vm._v("拒单")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":function($event){return _vm.handleInvalidate(_vm.obj, 1)}}},[_vm._v("确认")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }