<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
    size="50%"
    style="overflow-x: hidden"
  >
    <div
      :model="entity"
      style="overflow: hidden; width: 100%; margin-left: 20px"
    >
      <span style="float: left; width: 50%">
        店铺: {{ entity.BuyerStoreName }}
      </span>
      <span style="float: left; width: 50%; margin-left: -50px">
        <!--        {{ entity.Id.replace(/\s/g, "").replace(/(.{4})/g, "$1 ") }}-->
        单号: B{{
          entity.Id
            ? entity.Id.replace(/\s/g, "").replace(/(.{4})/g, "$1 ")
            : ""
        }}
      </span>
    </div>
    <div
      :model="entity"
      style="
        overflow: hidden;
        width: 100%;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 30px;
      "
    >
      <span style="float: left; width: 50%">
        日期: {{ entity.CreateTime ? entity.CreateTime.substring(0, 10) : "" }}
      </span>
      <span style="float: left; width: 50%; margin-left: -50px">
        客户: {{ entity.CompanyName }}
      </span>
    </div>
    <!-- <el-form ref="form" :model="entity" label-width="100px"> -->
    <!-- <el-form-item label="店铺:" prop="BuyerStoreName" >
        {{ entity.BuyerStoreName }}
      </el-form-item>
      <el-form-item label="单号:" prop="Id">
        {{ entity.Id }}
      </el-form-item> -->
    <!-- <el-form-item label="日期:" prop="CreateTime">
        {{ entity.CreateTime.substring(0, 10)}}
      </el-form-item>
      <el-form-item label="客户:" prop="CompanyName">
        {{ entity.CompanyName }}
      </el-form-item> -->
    <!-- </el-form> -->
    <el-table :data="details" style="width: 100%; left: 10px">
      <el-table-column prop="InventName" label="品名/编码" width="330">
      </el-table-column>
      <el-table-column prop="Quantity" label="数量"> </el-table-column>
      <!--      :formatter="stateFormat"-->
      <el-table-column prop="Price" label="单价">
        <template slot-scope="scope">
          {{ toThousands(scope.row.Price) }}
        </template>
      </el-table-column>
      <el-table-column prop="Money" label="金额">
        <template slot-scope="scope">
          {{ toThousands(scope.row.Money) }}
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="obj.IsConfirm == 0"
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="handleInvalidate(obj, 2)">拒单</el-button>
      <el-button
        type="primary"
        :loading="btnLoading"
        @click="handleInvalidate(obj, 1)"
        >确认</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import { getMonthRange, toThousands, formatDate } from "../../utils/Tools";
export default {
  components: {},
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      // 金额格式千分位
      toThousands,
      obj: {},
      drawer: false,
      direction: "rtl",
      entity: {},
      details: [],
      btnLoading: false,
      title: "",
      ProvinceList: [],
      CityList: [],
      CountyList: [],
    };
  },
  methods: {
    // 金额千分位
    stateFormat(row, column, cellValue) {
      console.log(cellValue);
      if (cellValue) {
        return Number(cellValue)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
    init() {
      this.entity = {};
      this.drawer = true;
      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      // });
    },
    openForm(row) {
      this.init();
      this.title = "订单详情";
      this.getTheData(row.Id);
      this.obj = row;
    },
    handleClose(done) {
      done();
    },
    getTheData(Id) {
      this.$http
        .post("/BO/ApplicationForm/GetApplicationDetail?id=" + Id)
        .then((res) => {
          if (res.Success) {
            this.entity = res.Data;
            this.details = this.entity.details;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 拒单
    handleInvalidate(scope, type) {
      this.$confirm("您确认要执行此操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post("/BO/ApplicationForm/ConfirmApplicationForm", {
            Id: scope.Id,
            IsConfirm: type,
          })
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
              this.drawer = false;
              this.$emit("sub");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style>
.drawerClass {
  padding: 10px;
}
</style>
